import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Heart from "../components/heart";
import {
  motion,
  useViewportScroll,
  useMotionValue,
  transform,
  useTransform
} from "framer-motion";
import Img from "gatsby-image";
import ReactGA from "react-ga";
function Index(props) {
  const [count, setCount] = useState(0);
  const [jazzHands, setJazzHands] = useState([]);
  let btnRef = React.createRef();
  let bodyRef = React.createRef();

  const hands = [
    props.data.hands.childImageSharp.fluid,
    {
      ...props.data.handsHd.childImageSharp.fluid,
      media: `(min-width: 768px)`
    }
  ];

  const { scrollYProgress } = useViewportScroll();
  const y = useMotionValue(0);
  const scale = useMotionValue(1);
  const opacity = useMotionValue(1);
  const opacityReverse = useTransform(opacity, [1, 0], [0, 1]);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "UA-61988338-12",
        gaOptions: {
          anonymize_ip: true
        }
      }
    ]);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  useEffect(() => {
    function updatePosition() {
      const body = bodyRef.current
        ? bodyRef.current.getBoundingClientRect()
        : { width: document.scrollX, height: document.scrollY };
      var num = -1 * Math.round(body.height / 10);
      var tScale = 0.75;
      if (body.width < 768) {
        num = -1 * Math.round(body.height / 10);
        tScale = 1;
      }
      y.set(transform(scrollYProgress.get(), [0, 1], [0, num]));
      scale.set(transform(scrollYProgress.get(), [0, 1], [1, tScale]));
      opacity.set(transform(scrollYProgress.get(), [0, 0.25], [1, 0]));
    }
    const unsubscribeY = scrollYProgress.onChange(updatePosition);
    updatePosition();
    return () => {
      unsubscribeY();
    };
  }, [scrollYProgress, y, scale, opacity, bodyRef]);

  const handleClick = () => {
    const btn = btnRef.current.getBoundingClientRect();
    const body = bodyRef.current.getBoundingClientRect();
    setCount(count + 1);
    var bodyX = Math.random() * body.width;
    var array = jazzHands;
    if (jazzHands.length > 10) {
      array.splice(0, 1);
    }
    let obj = {
      id: count,
      x: btn.x - btn.x / 2 - btn.width / 2 - 30,
      x2: bodyX - bodyX / 2,
      y: btn.top + window.scrollY - 40,
      scale: Math.random() * (1 - 0.3) + 0.3
    };
    Object.freeze(obj);
    setJazzHands([...array, obj]);

    ReactGA.event({
      category: "Heart",
      action: "click",
      value: 1
    });
  };

  return (
    <Layout
      location={props.location}
      title="Rädda en stockholmare"
      ogTitle="Hjärttrösta en stockholmare"
      desc="Visa omtanke genom att skicka handhjärtan till stockholmare som du är orolig för. Måhända är det en liten gest med tanke på hur de har det. Men kom ihåg, det är tanken som räknas."
      className="layout"
    >
      <div className="wrap" ref={bodyRef}>
        <div className="bg-wrap">
          <Img
            className="bg"
            fluid={props.data.bg.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 100%"
            fadeIn={true}
          />
        </div>
        <motion.div className="logo-wrap" style={{ y }}>
          <img
            src={"/radda_en_stockholmare_big-01.svg"}
            alt="Rädda en stockholmare"
            id="logo"
          />
          <img
            src={"/radda_en_mini-01.svg"}
            alt="Rädda en stockholmare"
            id="logo-small"
          />
        </motion.div>
        <motion.div className="hands-wrap" style={{ y, scale }}>
          <Img
            className="hands"
            fluid={hands}
            objectFit="contain"
            objectPosition="50% 100%"
            fadeIn={true}
          />
        </motion.div>
        <motion.div className="text" style={{ y, opacity }}>
          <h1>Hjärttrösta</h1>
          <motion.p style={{ opacity }}>
            Enligt statistiken så jobbar stockholmarna mer och sover sämre än
            oss. Visa att du tänker på dem genom att hjärttrösta.
          </motion.p>
        </motion.div>
      </div>
      <div className="wrap wrap-two">
        <div className="heart">
          <h1>{count}</h1>
          <motion.div
            style={{ cursor: "pointer", textAlign: "center" }}
            className="btn"
            whileHover={{ scale: 0.9 }}
            whileTap={{ scale: 1.4 }}
            onTouchStart={handleClick}
            onMouseDown={handleClick}
          >
            <img ref={btnRef} src={"/heart.svg"} alt="Hjärta" id="heart" />
          </motion.div>
          <br />
          <a
            href={
              "https://www.facebook.com/sharer/sharer.php?u=" +
              encodeURI("http://heart.raddaenstockholmare.se/share/?hearts=") +
              count
            }
            target="_blank"
            rel="noopener noreferrer"
            className="share"
          >
            Dela
          </a>
        </div>
      </div>
      <motion.div style={{ opacity: opacityReverse }} className="totalWrap">
        <Heart
          ease="easeOut"
          items={jazzHands}
          image={props.data.jazzHands.childImageSharp.fluid}
          opacity={{ opacity }}
          draggable="false"
        />
      </motion.div>
    </Layout>
  );
}

export default Index;

export const pageQuery = graphql`
  query {
    hands: file(relativePath: { regex: "//hands.png/gm" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    handsHd: file(relativePath: { regex: "//hands-hd.png/gm" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jazzHands: file(relativePath: { regex: "//jazzhands.png/gm" }) {
      childImageSharp {
        fluid(maxWidth: 125) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bg: file(relativePath: { regex: "//bg-puter.png/gm" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { dir: { regex: "/assets/images/gm" } }) {
      nodes {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            srcWebp
            srcSet
            srcSetWebp
            src
          }
        }
        base
      }
    }
  }
`;
